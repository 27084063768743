/*=============================================
=            svg tilt bottom            =
=============================================*/
.tiltbottom {
    /* position: absolute; */
    /* bottom: 0;
    left: 0; */
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.tiltbottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    /* height: 148px; */
}

@-moz-document url-prefix() {
    .tiltbottom svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 148px;
    }
}

.tiltbottom .shape-fill {
    fill: #ffffff;
}

/*=====  End of svg tilt bottom  ======*/

/*=============================================
=            svg tilt up            =
=============================================*/
.tiltup {
    /* position: absolute; */
    /* bottom: 0;
    left: 0; */
    width: 100%;
    overflow: hidden;
    line-height: 0;
    /* transform: rotate(180deg); */
}

.tiltup svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.tiltup .shape-fill {
    fill: #ffffff;
}

/*=====  End of svg tilt up  ======*/

/*=============================================
=            jquery connection            =
=============================================*/
connection {
    border: 4px solid #2188ff;
    border-radius: 300px;
}
/*=====  End of jquery connection  ======*/

/*=============================================
=            toggle            =
=============================================*/
.toggle-icon-activated {
    color: #fff;
}

/* .toggle-trigger-bis::after {
    content: "\f13a";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    margin-right: 10px;
}

.toggle-trigger-bis.activated::after {
    content: "\f139";
    font-family: "Font Awesome 5 Free";
    color: rgb(251 191 36);

} */

/*=====  End of toggle  ======*/

/*=============================================
=            background images            =
=============================================*/

.policy {
    background-image: url('../img/parliament.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: bottom -200 right; */
    /* background-attachment: fixed; */
    min-height: 500px;
}

.managing {
    background-image: url('../img/management.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: bottom -200 right; */
    /* background-attachment: fixed; */
    min-height: 500px;
}

.creative {
    background-image: url('../img/plan-color-design-conception-thirdman-5582867.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: bottom -200 right; */
    /* background-attachment: fixed; */
    min-height: 500px;
}

/*=====  End of background images  ======*/

/*=============================================
=            outline accordion            =
=============================================*/

.outline-my {
    outline-style: solid;
    outline-width: 2px;
}

/*=====  End of ring accordion  ======*/
