/*! http://responsiveslides.com v1.55 by @viljamis */

.rslides {
    position: relative;
    list-style: none;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
}

.rslides li {
    -webkit-backface-visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    left: 0;
    top: 0;
    overflow: clip;
    height: 795px;

}

.rslides li:first-child {
    position: relative;
    display: block;
    float: left;

}

.rslides img {
    display: block;
    /* height: auto; */
    /* height: 1920px; */
    object-fit: cover;
    float: left;
    width: 100%;
    height: 100%;
    border: 0;

}

.caption {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px;
    background: #000;
    opacity: 0.5;
    /* background: rgba(0, 0, 0, 0.8); */
    color: #fff;
}