/*=============================================
=            svg bars            =
=============================================*/
.barstop {
    /* position: absolute; */
    /* : 0;
    left: 0; */
    /* width: 100%;
    overflow: hidden;
    line-height: 0; */
    transform: rotate(180deg);
}

/* .barstop svg { */
/* position: relative; */
/* display: block; */
/* width: calc(100% + 1.3px); */
/* height: 148px; */
/* width: 900px; */
/* bottom: 0; */
/* } */

/*=====  End of svg bars  ======*/

/*=============================================
=            postit            =
=============================================*/

.postit {
    /* line-height: 1; */
    text-align: center;
    /* width: 275px; */
    /* margin: 25px; */
    /* min-height: 250px;
    max-height: 250px; */
    /* padding-top: 35px; */
    /* position: relative; */
    border: 1px solid #e8e8e8;
    /* border-top: 60px solid; */
    /* font-family: "Reenie Beanie";
    font-size: 3em; */
    border-bottom-right-radius: 60px 5px;
    /* display: inline-block; */
    /* background: #ffff88;  */
}

.postit:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: -0px;
    bottom: 20px;
    width: 200px;
    height: 25px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.4);
    -moz-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    -webkit-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    -o-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    -ms-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    transform: matrix(-1, -0.1, 0, 1, 0, 0);
}

.rotate {
    /* Safari */
    -webkit-transform: rotate(-10deg);
    /* Firefox */
    -moz-transform: rotate(-10deg);
    /* IE */
    -ms-transform: rotate(-10deg);
    /* Opera */
    -o-transform: rotate(-10deg);
    /* Internet Explorer */
    filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=1);
}

/*=====  End of postit  ======*/