@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background: none;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

* {
    margin: 0;
    padding: 0;
}

:root {
    --dk: #040d21;
    --primary-color: #212121;
    --background-color: #111;
    --font: sans-serif;
}


    /* select#contact_subject option[value="0"][disabled] {
        display: none;
    } */

    /* option {
        color: #000;
    } */

.body-text a {
    text-decoration: none;
    color: #0969da;
}

.circle {
    height: 125px;
    width: 125px;
    border-radius: 50%;
}

.body-text a:hover {
    text-decoration: underline;
}

.text-blue-custom {
    color: #27378f;
}

.banner {
    background: var(--dk);
}

.my-border-1 {
    border-style: solid;
    border-width: 1px;
}

.my-border-t-gray-1 {
    /* border-top-style: solid;
    border-width: 1px; */
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid #e2e8f0;
}

.my-border-t-gray-darker-1 {
    /* border-top-style: solid;
    border-width: 1px; */
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid #ccc;
}

/*
    Plugin : jTyper
    Copyright (C) 2018 - Mohammad Esmaeilzadeh
    Author URL: http://www.bugless.ir/
    Author Email: bugless.ir@yahoo.com
    This is a jQuery plugin for typing links
*/

.jTyper {
    /* list-style: none; */
    margin: 0px;
    padding: 0px;
}

/* PRELINE */
#navbar-collapse-basic>div>a>i {
    padding-right: 5px;
}

#navbar-collapse-basic .hs-dropdown button i {
    padding-right: 10px;
}



.hs-dropdown button {
    height: 100%;
}

.hs-dropdown button {
    height: 100%;
    border-bottom: 2px transparent solid;
}

.btn-calltoaction-full {
    @apply flex items-center w-full gap-2 px-4 py-3 font-normal text-white transition-all bg-yellow-500 bg-yellow-600 border border-transparent rounded-md outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-calltoaction {
    @apply flex items-center gap-2 px-4 py-3 font-normal text-white transition-all bg-yellow-500 bg-yellow-600 border border-transparent rounded-md outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-blue-full {
    @apply flex items-center w-full gap-2 px-4 py-3 font-normal text-white transition-all bg-blue-500 bg-blue-900 border border-transparent rounded-md outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-blue {
    @apply flex items-center gap-2 px-4 py-3 font-normal text-white transition-all bg-blue-500 bg-blue-900 border border-transparent rounded-md outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-orange-full {
    @apply flex items-center w-full gap-2 px-4 py-3 font-normal text-white transition-all bg-orange-600 bg-orange-700 border border-transparent rounded-md outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-orange {
    @apply flex items-center gap-2 px-4 py-3 font-normal text-white transition-all bg-orange-600 bg-orange-700 border border-transparent rounded-md outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-blue-light-full {
    @apply flex items-center w-full gap-2 px-4 py-3 font-normal text-white transition-all bg-blue-500 border border-transparent rounded-md outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-blue-light {
    @apply flex items-center gap-2 px-4 py-3 font-normal text-white transition-all bg-blue-400 bg-blue-500 border border-transparent rounded-md outline-none focus:ring-2 focus:ring-blue-500 hover:text-gray-900 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-blue-585 {
    @apply flex items-center gap-2 px-4 py-3 font-normal text-white transition-all bg-blue-400 border border-transparent rounded-md outline-none bg-blue-585 focus:ring-2 focus:ring-blue-500 hover:bg-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-cyan-550 {
    @apply flex items-center gap-2 px-4 py-3 font-normal text-white transition-all border border-transparent rounded-md outline-none bg-cyan-550 focus:ring-2 focus:ring-cyan-500 hover:bg-cyan-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-red-750 {
    @apply flex items-center gap-2 px-4 py-3 font-normal text-white transition-all border border-transparent rounded-md outline-none bg-red-750 focus:bg-cyan-500 focus:ring-2 focus:ring-red-500 hover:bg-red-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-cyan-full {
    @apply flex items-center w-full gap-2 px-4 py-3 font-normal text-white transition-all border border-transparent rounded-md outline-none bg-cyan-500 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-cyan {
    @apply flex items-center gap-2 px-4 py-3 font-normal text-white transition-all border border-transparent rounded-md outline-none bg-cyan-500 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-green-full {
    @apply flex items-center w-full gap-2 px-4 py-3 font-normal text-white transition-all bg-green-500 bg-green-600 border border-transparent rounded-md outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.btn-green {
    @apply flex items-center gap-2 px-4 py-3 font-normal text-white transition-all bg-green-500 bg-green-600 border border-transparent rounded-md outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800;
    height: 50px;
}

.danger {
    @apply px-4 py-3 text-red-500 bg-red-100 border-t-4 border-red-500 rounded-b shadow-md;
}

.success {
    @apply px-4 py-3 text-green-500 bg-teal-100 border-t-4 border-green-500 rounded-b shadow-md;
}

.icon-dot {
    font-size: 9px;
}

/*=============================================
=            search bar start            =
=============================================*/
.search-form-wrapper {
    display: none;
    position: absolute;
    width: fit-content;
    z-index: 9;
}

.search-form-wrapper.open {
    display: block;
    padding: 5;
    right: 20px;
    top: 58px;
    /* border: 1px solid #070918; */
}

.banner {
    background-image: url('../img/globe.jpg');
    background-color: #000024;
    background-repeat: no-repeat;
    background-size: 65%;
    background-position: right top 65px;
    background-attachment: fixed;
}

.index-banner-description{
    font-size: 65px;
    font-weight:900;
    line-height: 60px;
    text-shadow: #000 1px 0 10px;
}



.cunbinform {
    /* background-position: top 65 right; */
    background-image: url('../img/fondu-cuneiforme-binaire-degrade-vertical-big.jpg');
    background-color: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    /* border-radius: 0.75rem; */
    border: 1px solid #000;
    /* background-attachment: cover; */
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    .banner {
        background-size: 85%;
        background-position: top 80 right;
    }
}

/*=====  End of search bar end  ======*/

.btn-blue-light-flat {
    --tw-bg-opacity: 1;
    background-color: rgb(33 136 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    border-width: 1px;
    border-color: #0000;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    font-weight: 400;
    transition-property: all;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
}

.text-shadow-black{
    text-shadow: #000 1px 0 10px;
}

/*----------  MEDIA QUERIES START  ----------*/

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .banner {
        background-image: url('../img/globe.jpg');
        background-color: #000024;
        background-repeat: no-repeat;
        background-size: 300%;
        background-position: left 60% top 65px;
        background-attachment: fixed;
    }

    .index-banner-description{
        font-size: 45px;
        font-weight:900;
        line-height: 60px;
        text-shadow: #000 1px 0 10px;
    }
}

/*----------  MEDIA QUERIES END  ----------*/